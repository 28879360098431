/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import findIndex from 'lodash/findIndex'
import keyBy from 'lodash/keyBy'
import reject from 'lodash/reject'
import Vue from 'vue'

import { FETCH_PART_GROUPS } from '../../actions.type'
import {
  CREATE_PART_GROUP,
  REMOVE_PART_GROUP,
  SET_PART_GROUP_ALL_REQUEST,
  SET_PART_GROUP_ALL_VALUE,
  UPDATE_PART_GROUP
} from '../../mutations.type'

const state = {
  all: {
    request: {
      isLoading: undefined,
      isSuccess: undefined,
      isError: undefined,
      isFatalError: undefined,
      delay: undefined,
      lastRequestTime: undefined
    },
    value: []
  }
}

const getters = {
  partGroupAllValue: (state) => state.all.value,
  partGroupAllRequest: (state) => state.all.request,
  partGroupAllById: (state) => keyBy(state.all.value, 'id')
}

const actions = {
  async [FETCH_PART_GROUPS]({ commit, state }, params) {
    if (
      (state.all.request.isSuccess && !params?.isReload) ||
      state.all.request.isLoading ||
      state.all.request.isError
    ) {
      return
    }
    try {
      const { data } = await Vue.axios.get('part-group', {
        'axios-retry': {
          beforeTry: () => {
            commit(SET_PART_GROUP_ALL_REQUEST, {
              isLoading: true,
              isError: false
            })
          },
          afterTry: ({ delay, lastRequestTime }) => {
            commit(SET_PART_GROUP_ALL_REQUEST, {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime
            })
          }
        }
      })
      commit(SET_PART_GROUP_ALL_REQUEST, {
        isLoading: false,
        isSuccess: true
      })
      commit(SET_PART_GROUP_ALL_VALUE, data)
    } catch (e) {
      commit(SET_PART_GROUP_ALL_REQUEST, {
        isFatalError: true,
        isLoading: false
      })
    }
  },

  async SOCKET_CREATE_PART_GROUP({ commit }, value) {
    commit(CREATE_PART_GROUP, value)
  },

  async SOCKET_UPDATE_PART_GROUP({ commit }, value) {
    commit(UPDATE_PART_GROUP, value)
  },

  async SOCKET_REMOVE_PART_GROUP({ commit }, id) {
    commit(REMOVE_PART_GROUP, id)
  }
}

const mutations = {
  [SET_PART_GROUP_ALL_REQUEST](state, request) {
    state.all.request = {
      ...state.all.request,
      ...request
    }
  },
  [SET_PART_GROUP_ALL_VALUE](state, partGroups) {
    state.all.value = partGroups
  },
  [UPDATE_PART_GROUP](state, updatedPartGroup) {
    state.all.value.splice(
      findIndex(state.all.value, { id: updatedPartGroup.id }),
      1,
      updatedPartGroup
    )
  },
  [CREATE_PART_GROUP](state, createdPartGroup) {
    state.all.value.push(createdPartGroup)
  },
  [REMOVE_PART_GROUP](state, id) {
    state.all.value = reject(state.all.value, { id })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
