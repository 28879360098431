<template>
  <v-list-item v-if="isCan(permissions)" link :to="to" class="naw-drawer">
    <v-list-item-icon>
      <v-icon v-text="icon" />
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title class="naw-drawer-title">{{
        title
      }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex'

import { permissions } from '@/common/mixins'

export default {
  name: 'NavDrawerListItem',
  mixins: [permissions],
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    to: {
      type: [String, Object],
      required: true
    },
    permissions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  }
}
</script>

<style lang="scss" scoped>
.naw-drawer {
  &-title {
    text-transform: capitalize;
  }
}
</style>
