export const SET_CURRENT_TIMESTAMP = 'setCurrentTimestamp'
export const SET_DOCUMENT_TITLE = 'setDocumentTitle'

export const SET_LOADER_MODAL_VALUE = 'setLoaderModalValue'
export const ADD_NOTIFICATION = 'addNotification'
export const READ_ALL_NOTIFICATION = 'readAllNotification'

export const SET_AUTH = 'setAuth'
export const PURGE_AUTH = 'purgeAuth'
export const SET_USER = 'setUser'
export const SET_CURRENT_STAFF_REQUEST = 'setCurrentStaffRequest'
export const SET_CURRENT_STAFF_VALUE = 'setCurrentStaffValue'

export const SET_LOCALE_ALL_REQUEST = 'setLocaleAllRequest'
export const SET_LOCALE_ALL_VALUE = 'setLocaleAllValue'
export const CREATE_LOCALE = 'createLocale'
export const UPDATE_LOCALE = 'updateLocale'
export const REMOVE_LOCALE = 'removeLocale'

export const SET_BRAND_ALL_REQUEST = 'setBrandAllRequest'
export const SET_BRAND_ALL_VALUE = 'setBrandAllValue'
export const CREATE_BRAND = 'createBrand'
export const UPDATE_BRAND = 'updateBrand'
export const REMOVE_BRAND = 'removeBrand'

export const SET_EQ_TYPE_ALL_REQUEST = 'setEqTypeAllRequest'
export const SET_EQ_TYPE_ALL_VALUE = 'setEqTypeAllValue'
export const CREATE_EQ_TYPE = 'createEqType'
export const UPDATE_EQ_TYPE = 'updateEqType'
export const REMOVE_EQ_TYPE = 'removeEqType'

export const SET_MODEL_ALL_REQUEST = 'setModelAllRequest'
export const SET_MODEL_ALL_VALUE = 'setModelAllValue'
export const CREATE_MODEL = 'createModel'
export const UPDATE_MODEL = 'updateModel'
export const REMOVE_MODEL = 'removeModel'

export const SET_ORDER_STATUS_ALL_REQUEST = 'setOrderStatusAllRequest'
export const SET_ORDER_STATUS_ALL_VALUE = 'setOrderStatusAllValue'
export const CREATE_ORDER_STATUS = 'createOrderStatus'
export const UPDATE_ORDER_STATUS = 'updateOrderStatus'
export const REMOVE_ORDER_STATUS = 'removeOrderStatus'

export const SET_ORDER_TYPE_ALL_REQUEST = 'setOrderTypeAllRequest'
export const SET_ORDER_TYPE_ALL_VALUE = 'setOrderTypeAllValue'
export const UPDATE_ORDER_TYPE = 'updateOrderType'

export const SET_CLIENT_FAULT_ALL_REQUEST = 'setClientFaultAllRequest'
export const SET_CLIENT_FAULT_ALL_VALUE = 'setClientFaultAllValue'
export const CREATE_CLIENT_FAULT = 'createClientFault'
export const UPDATE_CLIENT_FAULT = 'updateClientFault'
export const REMOVE_CLIENT_FAULT = 'removeClientFault'

export const SET_CLIENT_FAULT_GROUP_ALL_REQUEST =
  'setClientFaultGroupAllRequest'
export const SET_CLIENT_FAULT_GROUP_ALL_VALUE = 'setClientFaultGroupAllValue'
export const CREATE_CLIENT_FAULT_GROUP = 'createClientFaultGroup'
export const UPDATE_CLIENT_FAULT_GROUP = 'updateClientFaultGroup'
export const REMOVE_CLIENT_FAULT_GROUP = 'removeClientFaultGroup'

export const SET_IDENT_FAULT_ALL_REQUEST = 'setIdentFaultAllRequest'
export const SET_IDENT_FAULT_ALL_VALUE = 'setIdentFaultAllValue'
export const CREATE_IDENT_FAULT = 'createIdentFault'
export const UPDATE_IDENT_FAULT = 'updateIdentFault'
export const REMOVE_IDENT_FAULT = 'removeIdentFault'

export const SET_IDENT_FAULT_GROUP_ALL_REQUEST = 'setIdentFaultGroupAllRequest'
export const SET_IDENT_FAULT_GROUP_ALL_VALUE = 'setIdentFaultGroupAllValue'
export const CREATE_IDENT_FAULT_GROUP = 'createIdentFaultGroup'
export const UPDATE_IDENT_FAULT_GROUP = 'updateIdentFaultGroup'
export const REMOVE_IDENT_FAULT_GROUP = 'removeIdentFaultGroup'

export const SET_CAUSE_FAULT_ALL_REQUEST = 'setCauseFaultAllRequest'
export const SET_CAUSE_FAULT_ALL_VALUE = 'setCauseFaultAllValue'
export const CREATE_CAUSE_FAULT = 'createCauseFault'
export const UPDATE_CAUSE_FAULT = 'updateCauseFault'
export const REMOVE_CAUSE_FAULT = 'removeCauseFault'

export const SET_CAUSE_FAULT_GROUP_ALL_REQUEST = 'setCauseFaultGroupAllRequest'
export const SET_CAUSE_FAULT_GROUP_ALL_VALUE = 'setCauseFaultGroupAllValue'
export const CREATE_CAUSE_FAULT_GROUP = 'createCauseFaultGroup'
export const UPDATE_CAUSE_FAULT_GROUP = 'updateCauseFaultGroup'
export const REMOVE_CAUSE_FAULT_GROUP = 'removeCauseFaultGroup'

export const SET_WORK_ALL_REQUEST = 'setWorkAllRequest'
export const SET_WORK_ALL_VALUE = 'setWorkAllValue'
export const CREATE_WORK = 'createWork'
export const UPDATE_WORK = 'updateWork'
export const REMOVE_WORK = 'removeWork'

export const SET_WORK_GROUP_ALL_REQUEST = 'setWorkGroupAllRequest'
export const SET_WORK_GROUP_ALL_VALUE = 'setWorkGroupAllValue'
export const CREATE_WORK_GROUP = 'createWorkGroup'
export const UPDATE_WORK_GROUP = 'updateWorkGroup'
export const REMOVE_WORK_GROUP = 'removeWorkGroup'

export const SET_PART_ALL_REQUEST = 'setPartAllRequest'
export const SET_PART_ALL_VALUE = 'setPartAllValue'
export const CREATE_PART = 'createPart'
export const UPDATE_PART = 'updatePart'
export const REMOVE_PART = 'removePart'

export const SET_PART_GROUP_ALL_REQUEST = 'setPartGroupAllRequest'
export const SET_PART_GROUP_ALL_VALUE = 'setPartGroupAllValue'
export const CREATE_PART_GROUP = 'createPartGroup'
export const UPDATE_PART_GROUP = 'updatePartGroup'
export const REMOVE_PART_GROUP = 'removePartGroup'

export const SET_CLIENT_ALL_REQUEST = 'setClientAllRequest'
export const SET_CLIENT_ALL_VALUE = 'setClientAllValue'
export const CREATE_CLIENT = 'createClient'
export const UPDATE_CLIENT = 'updateClient'
export const REMOVE_CLIENT = 'removeClient'

export const SET_CONTRACT_ALL_REQUEST = 'setContractAllRequest'
export const SET_CONTRACT_ALL_VALUE = 'setContractAllValue'
export const CREATE_CONTRACT = 'createContract'
export const UPDATE_CONTRACT = 'updateContract'
export const REMOVE_CONTRACT = 'removeContract'

export const SET_LOCATION_ALL_REQUEST = 'setLocationAllRequest'
export const SET_LOCATION_ALL_VALUE = 'setLocationAllValue'
export const CREATE_LOCATION = 'createLocation'
export const UPDATE_LOCATION = 'updateLocation'
export const REMOVE_LOCATION = 'removeLocation'

export const SET_COUNTRY_ALL_REQUEST = 'setCountryAllRequest'
export const SET_COUNTRY_ALL_VALUE = 'setCountryAllValue'
export const CREATE_COUNTRY = 'createCountry'
export const UPDATE_COUNTRY = 'updateCountry'
export const REMOVE_COUNTRY = 'removeCountry'

export const SET_REGION_ALL_REQUEST = 'setRegionAllRequest'
export const SET_REGION_ALL_VALUE = 'setRegionAllValue'
export const CREATE_REGION = 'createRegion'
export const UPDATE_REGION = 'updateRegion'
export const REMOVE_REGION = 'removeRegion'

export const SET_CITY_ALL_REQUEST = 'setCityAllRequest'
export const SET_CITY_ALL_VALUE = 'setCityAllValue'
export const CREATE_CITY = 'createCity'
export const UPDATE_CITY = 'updateCity'
export const REMOVE_CITY = 'removeCity'

export const SET_EQUIPMENT_ALL_REQUEST = 'setEquipmentAllRequest'
export const SET_EQUIPMENT_ALL_VALUE = 'setEquipmentAllValue'
export const CREATE_EQUIPMENT = 'createEquipment'
export const UPDATE_EQUIPMENT = 'updateEquipment'
export const REMOVE_EQUIPMENT = 'removeEquipment'

export const SET_STAFF_ALL_REQUEST = 'setStaffAllRequest'
export const SET_STAFF_ALL_VALUE = 'setStaffAllValue'
export const CREATE_STAFF = 'createStaff'
export const UPDATE_STAFF = 'updateStaff'
export const REMOVE_STAFF = 'removeStaff'

export const SET_ROLE_ALL_REQUEST = 'setRoleAllRequest'
export const SET_ROLE_ALL_VALUE = 'setRoleAllValue'
export const CREATE_ROLE = 'createRole'
export const UPDATE_ROLE = 'updateRole'
export const REMOVE_ROLE = 'removeRole'

export const SET_ZONE_ALL_REQUEST = 'setZoneAllRequest'
export const SET_ZONE_ALL_VALUE = 'setZoneAllValue'
export const CREATE_ZONE = 'createZone'
export const UPDATE_ZONE = 'updateZone'
export const REMOVE_ZONE = 'removeZone'

export const SET_ORDER_ONE_REQUEST = 'setOrderOneRequest'
export const SET_ORDER_ONE_VALUE = 'setOrderOneValue'
export const SET_ORDER_ALL_REQUEST = 'setOrderAllRequest'
export const SET_ORDER_ALL_VALUE = 'setOrderAllValue'
export const SET_ORDER_ALL_COUNT = 'setOrderAllCount'
export const CREATE_ORDER = 'createOrder'
export const UPDATE_ORDER = 'updateOrder'

export const SET_GLOBAL_SETTING_ALL_REQUEST = 'setGlobalSettingAllRequest'
export const SET_GLOBAL_SETTING_ALL_VALUE = 'setGlobalSettingAllValue'
export const UPDATE_GLOBAL_SETTING = 'updateGlobalSetting'

export const SET_API_KEY_ALL_REQUEST = 'setApiKeyAllRequest'
export const SET_API_KEY_ALL_VALUE = 'setApiKeyAllValue'
export const CREATE_API_KEY = 'createApiKey'
export const UPDATE_API_KEY = 'updateApiKey'
export const REMOVE_API_KEY = 'removeApiKey'
