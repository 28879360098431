<template>
  <v-select
    :items="locales"
    v-model="$root.$i18n.locale"
    style=""
    dense
    class="locale-select"
    item-text="title"
    item-value="value"
  />
</template>

<script>
export default {
  name: 'LocaleSelect',
  data() {
    return {
      locales: [
        { title: 'RU', value: 'ru' },
        { title: 'UA', value: 'uk' },
        { title: 'EN', value: 'en' },
        { title: 'GE', value: 'ka' },
        { title: 'LT', value: 'lt' }
      ]
    }
  },
  watch: {
    '$root.$i18n.locale'(val) {
      localStorage.locale = val
      document.getElementsByTagName('html')[0].lang = val
      this.$moment.locale(val)
      this.$vuetify.lang.current = val
      //
    }
  },
  async beforeMount() {
    if (localStorage.locale) {
      this.$root.$i18n.locale = localStorage.locale
      document.getElementsByTagName('html')[0].lang = this.$root.$i18n.locale
      this.$moment.locale(this.$root.$i18n.locale)
      this.$vuetify.lang.current = this.$root.$i18n.locale
    }
  }
}
</script>

<style lang="scss">
.locale-select {
  width: 4rem;
  height: 2em;
  .v-input {
    &__slot:before {
      border: none !important;
    }
  }
}
</style>
