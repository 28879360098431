/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import findIndex from 'lodash/findIndex'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import reject from 'lodash/reject'
import Vue from 'vue'

import { FETCH_ORDER_STATUSES } from '../../actions.type'
import {
  CREATE_ORDER_STATUS,
  REMOVE_ORDER_STATUS,
  SET_ORDER_STATUS_ALL_REQUEST,
  SET_ORDER_STATUS_ALL_VALUE,
  UPDATE_ORDER_STATUS
} from '../../mutations.type'

const state = {
  all: {
    request: {
      isLoading: undefined,
      isSuccess: undefined,
      isError: undefined,
      isFatalError: undefined,
      delay: undefined,
      lastRequestTime: undefined
    },
    value: []
  }
}

const getters = {
  orderStatusAllValue: (state) => state.all.value,
  orderStatusAllRequest: (state) => state.all.request,
  orderStatusAllById: (state) => keyBy(state.all.value, 'id')
}

const actions = {
  async [FETCH_ORDER_STATUSES]({ commit, state }, params) {
    if (
      (state.all.request.isSuccess && !params?.isReload) ||
      state.all.request.isLoading ||
      state.all.request.isError
    ) {
      return
    }
    try {
      const { data } = await Vue.axios.get('order-status', {
        'axios-retry': {
          beforeTry: () => {
            commit(SET_ORDER_STATUS_ALL_REQUEST, {
              isLoading: true,
              isError: false
            })
          },
          afterTry: ({ delay, lastRequestTime }) => {
            commit(SET_ORDER_STATUS_ALL_REQUEST, {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime
            })
          }
        }
      })
      commit(SET_ORDER_STATUS_ALL_REQUEST, {
        isLoading: false,
        isSuccess: true
      })
      commit(SET_ORDER_STATUS_ALL_VALUE, data)
    } catch (e) {
      commit(SET_ORDER_STATUS_ALL_REQUEST, {
        isFatalError: true,
        isLoading: false
      })
    }
  },

  async SOCKET_CREATE_ORDER_STATUS({ commit }, value) {
    commit(CREATE_ORDER_STATUS, value)
  },

  async SOCKET_UPDATE_ORDER_STATUS({ commit }, value) {
    commit(UPDATE_ORDER_STATUS, value)
  },

  async SOCKET_REMOVE_ORDER_STATUS({ commit }, id) {
    commit(REMOVE_ORDER_STATUS, id)
  }
}

const mutations = {
  [SET_ORDER_STATUS_ALL_REQUEST](state, request) {
    state.all.request = {
      ...state.all.request,
      ...request
    }
  },
  [SET_ORDER_STATUS_ALL_VALUE](state, orderStatuses) {
    const items = []
    map(orderStatuses, (status) => {
      const item = status
      if (status.from) {
        item.from = JSON.parse(status.from)
      } else {
        item.from = []
      }

      if (status.to) {
        item.to = JSON.parse(status.to)
      } else {
        item.to = []
      }
      items.push(item)
    })

    state.all.value = items
  },
  [UPDATE_ORDER_STATUS](state, updatedOrderStatus) {
    const status = updatedOrderStatus
    if (status.from) {
      status.from = JSON.parse(status.from)
    } else {
      status.from = []
    }

    if (status.to) {
      status.to = JSON.parse(status.to)
    } else {
      status.to = []
    }

    state.all.value.splice(
      findIndex(state.all.value, { id: updatedOrderStatus.id }),
      1,
      status
    )
  },
  [CREATE_ORDER_STATUS](state, createdOrderStatus) {
    const status = createdOrderStatus
    if (status.from) {
      status.from = JSON.parse(status.from)
    } else {
      status.from = []
    }

    if (status.to) {
      status.to = JSON.parse(status.to)
    } else {
      status.to = []
    }
    state.all.value.push(status)
  },
  [REMOVE_ORDER_STATUS](state, id) {
    state.all.value = reject(state.all.value, { id })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
