import ru from 'vuetify/lib/locale/ru'

export default {
  account: 'аккаунт',
  accountNotActive: 'аккаунт отключен',
  accounts: 'аккаунты',
  active: 'активен',
  add: 'добавить',
  addAccount: 'добавить аккаунт',
  addSelected: 'добавить выбранное',
  address: 'адрес',
  additionalLanguage: 'дополнительный язык',
  administration: 'администрирование',
  all: 'все',
  any: 'любой',
  apiDocs: 'API документация',
  apiKeys: 'API ключи',
  apply: 'применить',
  allWeek: 'вся неделя',
  back: 'назад',
  backToLoginPage: 'вернуться на страницу входа',
  beverage: 'напиток',
  beverages: 'напитки',
  brand: 'бренд',
  brands: 'бренды',
  businessHours: 'время работы',
  byDays: 'по дням',
  cancel: 'отмена',
  cancelDelete: 'отменить удаление?',
  cantDeleteFirstDeleteRelations:
    'удаление не разрешено, сначала удалите связанные объекты',
  cantDeleteDefaultValue: 'нелзя удалить значение по умолчанию',
  cause: 'причина',
  causeFaultGroup: 'группа причин неисправностей',
  causeFaultGroups: 'группы причин неисправностей',
  causeFaults: 'причины неисправностей',
  causeGroups: 'группы причин',
  causes: 'причины',
  changePassword: 'изменить пароль',
  cities: 'города',
  city: 'город',
  client: 'контрагент',
  clientFault: 'причина вызова',
  clientFaultGroup: 'группа причины вызова',
  clientFaultGroups: 'группы причин вызова',
  clientFaults: 'причины вызова',
  clients: 'контрагенты',
  close: 'закрыть',
  closed: 'закрыто',
  code: 'код',
  color: 'Цвет',
  comment: 'комментарий',
  completedDate: 'дата завершения',
  connectionLost: 'соединение потеряно',
  connectionRestored: 'соединение восстановлено',
  confirmDelete: 'вы уверены, что хотите удалить этот элемент?',
  confirmPassword: 'подтвердите пароль',
  connectServerError: 'ошибка подключения к серверу',
  contactPerson: 'контактное лицо',
  contactPersons: 'контактные лица',
  contract: 'договор',
  contractor: 'исполнитель',
  contracts: 'договоры',
  controlTechConditions: 'контроль тех. условий',
  counter: 'счетчик',
  counterFrom: 'счетчик от',
  counterTo: 'счетчик до',
  countries: 'страны',
  country: 'страна',
  create: 'создать',
  createdBasedOrder: 'создано на основании наряд-заказа',
  createdDate: 'дата создания',
  createdOrder: 'создан наряд-заказ',
  date: 'дата',
  dateEnd: 'дата окончания',
  dateStart: 'дата начала',
  days: 'дней',
  daysDot: 'д.',
  decisionMakerName: 'имя ЛПР',
  decisionMakerPhone: 'телефон ЛПР',
  default: 'по умолчанию',
  defaultLocationBusinessHours: 'время работы локаций по умолчанию',
  defaultPageTitle: 'CM-Service DESK',
  defaultReactionTime: 'время реакции по умолчанию',
  defaultSolutionTime: 'время решения по умолчанию',
  delete: 'удалить',
  deleteAccount: 'удалить аккаунт',
  deleteUserAccount:
    'аккаунт будет удален, пользователь больше не сможет войти в систему.',
  description: 'описание',
  details: 'реквизиты',
  distance: 'расстояние',
  distanceFromService: 'удаленность от СЦ',
  document: 'документ',
  download: 'скачать',
  edit: 'редактировать',
  editDate: 'изменить дату',
  employee: 'сотрудник',
  eqTypes: 'типы оборудования',
  equipment: 'оборудование',
  equipments: 'оборудование',
  error: 'ошибка',
  errorLoadingFromServer: 'ошибка при загрузке с сервера',
  every: 'каждые',
  fault: 'неисправность',
  faultCode: 'код неисправности',
  faultGroup: 'группа неисправностей',
  faultGroups: 'группы неисправностей',
  faults: 'неисправности',
  filter: 'фильтр',
  filters: 'фильтры',
  firstDeleteAllRelations: 'сначала вам нужно удалить все связанные элементы',
  firstName: 'имя',
  forgotPassword: 'забыли пароль?',
  from: 'начало',
  fromClients: 'от клиентов',
  generalInfo: 'общая информация',
  globals: 'глобальные',
  gps: 'gps',
  group: 'группа',
  groups: 'группы',
  hide: 'скрыть',
  home: 'главная',
  hours: 'часов',
  hoursDot: 'ч.',
  identFaultGroups: 'группы выявленных неисправностей',
  identFaults: 'выявленные неисправности',
  idents: 'выявленные',
  instruction: 'инструкция',
  key: 'ключ',
  km: 'км',
  language: 'язык',
  lastName: 'фамилия',
  loading: 'загрузка',
  placement: 'местоположение',
  places: 'местоположения',
  location: 'локация',
  locations: 'локации',
  login: 'вход',
  loginIncorect: 'не верный логин или пароль',
  mailingList: 'емайлы для рассылки',
  managerEmail: 'email менеджера',
  managerName: 'имя менеджера',
  managerPhone: 'телефон менеджера',
  maximum: 'максимум',
  maxReactionTimeByContract: 'макс. время реакции по контракту',
  maxSolutionTimeByContract: 'макс. время решения по договору',
  maxTimeOfWorkByContract: 'макс. время работы по договору',
  maxWorkTime: 'максимальное время работы',
  middleName: 'отчество',
  minimum: 'минимум',
  minutes: 'минут',
  minutesDot: 'мин.',
  mode: 'режим',
  model: 'модель',
  models: 'модели',
  name: 'имя',
  new: 'новый',
  newPassword: 'новый пароль',
  nextTry: 'следующая попытка',
  no: 'нет',
  noData: 'нет данных',
  notActive: 'не активен',
  notFound: 'не найдено',
  notFoundDescription: 'ресурс мог быть перемещен или удален',
  notReceiveCode: 'не получили код?',
  notRepeat: 'не повторяется',
  notification: 'уведомление',
  notifications: 'уведомления',
  notificationTimeTaked: 'время уведомления до назначен',
  notificationTimeReacted: 'время уведомления до реакции',
  notificationTimeResolved: 'время уведомления до  решения ',
  now: 'сейчас',
  number: 'номер',
  object: 'объект',
  objects: 'объекты',
  onlyTheir: 'только свои',
  opened: 'открыто',
  order: 'наряд-заказ',
  orderBusinessHours: 'время приёма наряд-заказов',
  orderCreatorDetails: 'подробности наряд-заказа',
  orderCreatorName: 'имя создателя наряд-заказа',
  orderCreatorPhone: 'телефон создателя наряд-заказа',
  orderCreatorType: 'тип создателя наряд-заказа',
  orderEditRules: 'правила редактирования наряд-заказа',
  orderStatus: 'статус наряд-заказа',
  orderStatuses: 'статусы наряд-заказов',
  orderStatusHistory: 'история статусов наряд-заказов',
  orderTriggerHint:
  'новый наряд-заказ будет создан при переходе текущего в этот статус',
  orderType: 'тип наряд-заказа',
  orderTypes: 'типы наряд-заказов',
  orders: 'наряд-заказы',
  otherFault: 'другая причина вызова',
  otherM: 'другой',
  otherW: 'другая',
  owner: 'владелец',
  owners: 'владельцы',
  part: 'запчасть',
  partGroup: 'группа запчастей',
  partGroups: 'группы запчастей',
  parts: 'запчасти',
  password: 'пароль',
  period: 'период',
  permissions: 'разрешения',
  phone: 'телефон',
  phones: 'телефоны',
  pressure: 'давление',
  range: 'диапазон',
  reaction: 'реакция',
  reactionDate: 'дата начала работ',
  reactionTime: 'время реакции',
  reactionTimer: 'таймер реакции',
  region: 'область',
  regions: 'области',
  removeSelected: 'удалить выбранное',
  repeat: 'повторять',
  repeatedOrder: 'повторяющийся наряд-заказ',
  resend: 'отправить еще раз',
  reset: 'сбросить',
  resetSort: 'сбросить сортировку',
  retry: 'повторить',
  role: 'роль',
  roles: 'роли',
  save: 'сохранить',
  savePassword: 'сохранить пароль',
  salesByBeverages: 'продажи по напиткам',
  salesByWeekDays: 'продажи по дням недели',
  salesByHours: 'продажи по часам',
  search: 'поиск',
  selectAll: 'выбрать все',
  selectDaysOfTheWeek: 'выберите дни недели',
  sendCode: 'отправить код',
  serialNumber: 'серийный номер',
  serverError: 'ошибка сервера',
  serverValidationError: 'ошибка валидации',
  serviceCenter: 'сервисный центр',
  settings: 'настройки',
  scanQrForServiceRequest:
    'Отсканируйте этот QR для создания наряд-заказа на ремонт',
  shortName: 'имя',
  show: 'показать',
  showMore: 'показать еще',
  sla: 'соглашение об уровне обслуживания',
  solution: 'решение',
  solutionDate: 'дата окончания работ',
  solutionTime: 'время решения',
  solutionTimer: 'таймер решения',
  sort: 'порядок',
  staff: 'сотрудник',
  staffs: 'сотрудники',
  state: 'состояние',
  status: 'состояние',
  statuses: 'состояния',
  stopNotifications: 'остановить уведомления',
  stopReactionTimer: 'остановить таймер реакции',
  stopSolutionTimer: 'остановить таймер решения',
  superAdmin: 'супер-админ',
  tableWidth: 'ширина таблицы',
  telegram: 'телеграм',
  telemetry: 'телеметрия',
  time: 'время',
  timeByFaultType: 'время по типу неисправности',
  timeDefault: 'время по умолчанию',
  timeOfWork: 'время работы',
  title: 'название',
  to: 'окончание',
  toRecoverPassword:
    'чтобы восстановить пароль, введите свой номер телефона, и мы пришлем вам SMS с кодом восстановления.',
  total: 'всего',
  transitionFrom: 'переход с',
  transitionTo: 'переход на',
  trigger: 'триггер',
  type: 'тип',
  types: 'типы',
  unselectAll: 'снять все',
  user: 'пользователь',
  userName: 'имя пользователя',
  users: 'пользователи',
  verifyCode: 'проверить код',
  video: 'видео',
  voltage: 'напряжение',
  waitLoadingFromServer: 'пожалуйста, дождитесь окончания загрузки с сервера',
  weeks: 'недели',
  withoutAccount: 'без аккаунта',
  work: 'работа',
  workTime: 'время работы',
  workGroups: 'группы работ',
  works: 'работы',
  yes: 'да',
  zone: 'зона',
  zones: 'зоны',
  validation: {
    alpha: 'должен(но) содержать только буквы',
    alreadyTaked: 'уже занято',
    alreadyStaff: 'другой исполнитель уже указан, перезагрузите страницу',
    email: 'должен быть действительный адрес электронной почты',
    emails: 'вводите электронную почту через запятую',
    integer: 'должно быть целым числом',
    ipVsPort: 'должен быть допустимым ip и портом: xxx.xxx.xxx.xxx:xxxxx',
    isChangedValue: 'выберите новое значение',
    length: 'должен иметь {n} символ | должен иметь {n} символов',
    maxLength: 'макс. количество символов: {n}',
    maxValue: 'максимальное значение - {n}',
    minLength: 'мин. количество символов: {n}',
    minValue: 'минимальное значение - {n}',
    numeric: 'должно быть числом',
    password: {
      hasLowerCase: 'строчную букву',
      hasNumbers: 'цифру',
      hasSymbol: 'символ',
      hasUpperCase: 'заглавную букву',
      minLength: 'мин. 8 символов',
      mustContain: 'пароль должен содержать:'
    },
    passwordMismatch: 'пароли не совпадают',
    phone: 'должен быть действительным телефоном: +XXXXXXXXXXXX',
    repeatedOrderDate:
    'внимание! Дата следующего наряд-заказа больше даты окончания договора на обслуживание оборудования, наряд-заказ может быть не создан!',
    required: 'не может быть пустым',
    sameAs: 'не совпадает с: {n}',
    url: 'должна быть действующая URL-ссылка'
  },
  weekDays: {
    sunday: 'воскресенье',
    monday: 'понедельник',
    tuesday: 'вторник',
    wednesday: 'среда',
    thursday: 'четверг',
    friday: 'пятница',
    saturday: 'суббота',
    holiday: 'выходной',
    sun: 'вc',
    mon: 'пн',
    tue: 'вт',
    wed: 'ср',
    thu: 'чт',
    fri: 'пт',
    sat: 'сб'
  },
  $vuetify: ru
}
