/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import findIndex from 'lodash/findIndex'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import reject from 'lodash/reject'
import Vue from 'vue'

import { FETCH_CONTRACTS } from '../../actions.type'
import {
  CREATE_CONTRACT,
  REMOVE_CONTRACT,
  SET_CONTRACT_ALL_REQUEST,
  SET_CONTRACT_ALL_VALUE,
  UPDATE_CONTRACT
} from '../../mutations.type'

const state = {
  all: {
    request: {
      isLoading: undefined,
      isSuccess: undefined,
      isError: undefined,
      isFatalError: undefined,
      delay: undefined,
      lastRequestTime: undefined
    },
    value: []
  }
}

const getters = {
  contractAllValue: (state) => state.all.value,
  contractAllRequest: (state) => state.all.request,
  contractAllById: (state) => keyBy(state.all.value, 'id'),
  contractAllByClientId: (state) => groupBy(state.all.value, 'client.id')
}

const actions = {
  async [FETCH_CONTRACTS]({ commit, state }, params) {
    if (
      (state.all.request.isSuccess && !params?.isReload) ||
      state.all.request.isLoading ||
      state.all.request.isError
    ) {
      return
    }
    try {
      const { data } = await Vue.axios.get('contract', {
        'axios-retry': {
          beforeTry: () => {
            commit(SET_CONTRACT_ALL_REQUEST, {
              isLoading: true,
              isError: false
            })
          },
          afterTry: ({ delay, lastRequestTime }) => {
            commit(SET_CONTRACT_ALL_REQUEST, {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime
            })
          }
        }
      })
      commit(SET_CONTRACT_ALL_REQUEST, {
        isLoading: false,
        isSuccess: true
      })
      commit(SET_CONTRACT_ALL_VALUE, data)
    } catch (e) {
      commit(SET_CONTRACT_ALL_REQUEST, {
        isFatalError: true,
        isLoading: false
      })
    }
  },

  async SOCKET_CREATE_CONTRACT({ commit }, value) {
    commit(CREATE_CONTRACT, value)
  },

  async SOCKET_UPDATE_CONTRACT({ commit }, value) {
    commit(UPDATE_CONTRACT, value)
  },

  async SOCKET_REMOVE_CONTRACT({ commit }, id) {
    commit(REMOVE_CONTRACT, id)
  }
}

const mutations = {
  [SET_CONTRACT_ALL_REQUEST](state, request) {
    state.all.request = {
      ...state.all.request,
      ...request
    }
  },
  [SET_CONTRACT_ALL_VALUE](state, contracts) {
    state.all.value = contracts
  },
  [UPDATE_CONTRACT](state, updatedContract) {
    state.all.value.splice(
      findIndex(state.all.value, { id: updatedContract.id }),
      1,
      updatedContract
    )
  },
  [CREATE_CONTRACT](state, createdContract) {
    state.all.value.push(createdContract)
  },
  [REMOVE_CONTRACT](state, id) {
    state.all.value = reject(state.all.value, { id })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
