import moment from 'moment-timezone'
import Vue from 'vue'
import Vuex from 'vuex'

import apiKey from './modules/apiKey'
import auth from './modules/auth'
import brand from './modules/brand'
import causeFault from './modules/causeFault'
import causeFaultGroup from './modules/causeFaultGroup'
import city from './modules/city'
import client from './modules/client'
import clientFault from './modules/clientFault'
import clientFaultGroup from './modules/clientFaultGroup'
import contract from './modules/contract'
import country from './modules/country'
import eqType from './modules/eq-type'
import equipment from './modules/equipment'
import globalSetting from './modules/global-setting'
import identFault from './modules/identFault'
import identFaultGroup from './modules/identFaultGroup'
import locale from './modules/locale'
import location from './modules/location'
import model from './modules/model'
import notification from './modules/notification'
import order from './modules/order'
import orderStatus from './modules/orderStatus'
import orderType from './modules/orderType'
import part from './modules/part'
import partGroup from './modules/partGroup'
import region from './modules/region'
import role from './modules/role'
import staff from './modules/staff'
import work from './modules/work'
import workGroup from './modules/workGroup'
import zone from './modules/zone'
import {
  SET_CURRENT_TIMESTAMP,
  SET_DOCUMENT_TITLE,
  SET_LOADER_MODAL_VALUE
} from './mutations.type'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    timestamp: new Date(Date.UTC()),
    documentTitle: '',
    loaderModal: {
      isLoading: false,
      isError: false,
      isFatalError: false
    }
  },
  getters: {
    timestampNow: (state) => state.timestamp,
    documentTitle: (state) => state.documentTitle,
    loaderModalValue: (state) => state.loaderModal
  },
  mutations: {
    [SET_CURRENT_TIMESTAMP](state, timestamp) {
      state.timestamp = timestamp
    },
    [SET_DOCUMENT_TITLE](state, title) {
      state.documentTitle = title
      document.title = title
    },
    [SET_LOADER_MODAL_VALUE](state, value) {
      state.loaderModal = { ...value }
    }
  },
  actions: {},
  modules: {
    auth,
    apiKey,
    locale,
    brand,
    eqType,
    model,
    orderStatus,
    orderType,
    clientFault,
    clientFaultGroup,
    identFault,
    identFaultGroup,
    causeFault,
    causeFaultGroup,
    part,
    partGroup,
    work,
    workGroup,
    client,
    contract,
    location,
    country,
    region,
    city,
    equipment,
    staff,
    role,
    zone,
    order,
    globalSetting,
    notification
  },
  plugins: [
    (store) => {
      const timeout = () => {
        setTimeout(() => {
          store.commit(SET_CURRENT_TIMESTAMP, moment().unix())
          timeout()
        }, 1000)
      }
      timeout()
    }
  ]
})
