/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import findIndex from 'lodash/findIndex'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import reject from 'lodash/reject'
import Vue from 'vue'

import { FETCH_ZONES } from '../../actions.type'
import {
  CREATE_ZONE,
  REMOVE_ZONE,
  SET_ZONE_ALL_REQUEST,
  SET_ZONE_ALL_VALUE,
  UPDATE_ZONE
} from '../../mutations.type'

const state = {
  all: {
    request: {
      isLoading: undefined,
      isSuccess: undefined,
      isError: undefined,
      isFatalError: undefined,
      delay: undefined,
      lastRequestTime: undefined
    },
    value: []
  }
}

const getters = {
  zoneAllValue: (state) => state.all.value,
  zoneAllRequest: (state) => state.all.request,
  zoneAllById: (state) => keyBy(state.all.value, 'id'),
  zoneAllByLocationId: (state) => {
    const result = {}
    map(state.all.value, (val) => {
      map(val.locations, (location) => {
        if (result[location.id]) {
          result[location.id].push(val)
        } else {
          result[location.id] = [val]
        }
      })
    })
    return result
  },
  zoneAllByStaffId: (state) => {
    const result = {}
    map(state.all.value, (val) => {
      map(val.staffs, (staff) => {
        if (result[staff.id]) {
          result[staff.id].push(val)
        } else {
          result[staff.id] = [val]
        }
      })
    })
    return result
  }
}

const actions = {
  async [FETCH_ZONES]({ commit, state }, params) {
    if (
      (state.all.request.isSuccess && !params?.isReload) ||
      state.all.request.isLoading ||
      state.all.request.isError
    ) {
      return
    }
    try {
      const { data } = await Vue.axios.get('zone', {
        'axios-retry': {
          beforeTry: () => {
            commit(SET_ZONE_ALL_REQUEST, {
              isLoading: true,
              isError: false
            })
          },
          afterTry: ({ delay, lastRequestTime }) => {
            commit(SET_ZONE_ALL_REQUEST, {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime
            })
          }
        }
      })
      commit(SET_ZONE_ALL_REQUEST, {
        isLoading: false,
        isSuccess: true
      })
      commit(SET_ZONE_ALL_VALUE, data)
    } catch (e) {
      commit(SET_ZONE_ALL_REQUEST, {
        isFatalError: true,
        isLoading: false
      })
    }
  },

  async SOCKET_CREATE_ZONE({ commit }, value) {
    commit(CREATE_ZONE, value)
  },

  async SOCKET_UPDATE_ZONE({ commit }, value) {
    commit(UPDATE_ZONE, value)
  },

  async SOCKET_REMOVE_ZONE({ commit }, id) {
    commit(REMOVE_ZONE, id)
  }
}

const mutations = {
  [SET_ZONE_ALL_REQUEST](state, request) {
    state.all.request = {
      ...state.all.request,
      ...request
    }
  },
  [SET_ZONE_ALL_VALUE](state, zones) {
    state.all.value = zones
  },
  [UPDATE_ZONE](state, updatedZone) {
    state.all.value.splice(
      findIndex(state.all.value, { id: updatedZone.id }),
      1,
      updatedZone
    )
  },
  [CREATE_ZONE](state, createdZone) {
    state.all.value.push(createdZone)
  },
  [REMOVE_ZONE](state, id) {
    state.all.value = reject(state.all.value, { id })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
