/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import findIndex from 'lodash/findIndex'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import reject from 'lodash/reject'
import Vue from 'vue'

import { FETCH_IDENT_FAULTS } from '../../actions.type'
import {
  CREATE_IDENT_FAULT,
  REMOVE_IDENT_FAULT,
  SET_IDENT_FAULT_ALL_REQUEST,
  SET_IDENT_FAULT_ALL_VALUE,
  UPDATE_IDENT_FAULT
} from '../../mutations.type'

const state = {
  all: {
    request: {
      isLoading: undefined,
      isSuccess: undefined,
      isError: undefined,
      isFatalError: undefined,
      delay: undefined,
      lastRequestTime: undefined
    },
    value: []
  }
}

const getters = {
  identFaultAllValue: (state) => state.all.value,
  identFaultAllRequest: (state) => state.all.request,
  identFaultAllById: (state) => keyBy(state.all.value, 'id'),
  identFaultAllByGroupId: (state) =>
    groupBy(state.all.value, 'identFaultGroup.id')
}

const actions = {
  async [FETCH_IDENT_FAULTS]({ commit, state }, params) {
    if (
      (state.all.request.isSuccess && !params?.isReload) ||
      state.all.request.isLoading ||
      state.all.request.isError
    ) {
      return
    }
    try {
      const { data } = await Vue.axios.get('ident-fault', {
        'axios-retry': {
          beforeTry: () => {
            commit(SET_IDENT_FAULT_ALL_REQUEST, {
              isLoading: true,
              isError: false
            })
          },
          afterTry: ({ delay, lastRequestTime }) => {
            commit(SET_IDENT_FAULT_ALL_REQUEST, {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime
            })
          }
        }
      })
      commit(SET_IDENT_FAULT_ALL_REQUEST, {
        isLoading: false,
        isSuccess: true
      })
      commit(SET_IDENT_FAULT_ALL_VALUE, data)
    } catch (e) {
      commit(SET_IDENT_FAULT_ALL_REQUEST, {
        isFatalError: true,
        isLoading: false
      })
    }
  },

  async SOCKET_CREATE_IDENT_FAULT({ commit }, value) {
    commit(CREATE_IDENT_FAULT, value)
  },

  async SOCKET_UPDATE_IDENT_FAULT({ commit }, value) {
    commit(UPDATE_IDENT_FAULT, value)
  },

  async SOCKET_REMOVE_IDENT_FAULT({ commit }, id) {
    commit(REMOVE_IDENT_FAULT, id)
  }
}

const mutations = {
  [SET_IDENT_FAULT_ALL_REQUEST](state, request) {
    state.all.request = {
      ...state.all.request,
      ...request
    }
  },
  [SET_IDENT_FAULT_ALL_VALUE](state, identFaults) {
    state.all.value = identFaults
  },
  [UPDATE_IDENT_FAULT](state, updatedIdentFault) {
    state.all.value.splice(
      findIndex(state.all.value, { id: updatedIdentFault.id }),
      1,
      updatedIdentFault
    )
  },
  [CREATE_IDENT_FAULT](state, createdIdentFault) {
    state.all.value.push(createdIdentFault)
  },
  [REMOVE_IDENT_FAULT](state, id) {
    state.all.value = reject(state.all.value, { id })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
