<template>
  <v-list-group prepend-icon="mdi-cog">
    <template v-slot:activator>
      <v-list-item-title>{{ $t('settings') }}</v-list-item-title>
    </template>

    <template v-for="(link, i) in settingLinks">
      <template v-if="isCan(link.permissions)">
        <v-list-item v-if="!link.items" :key="i" link :to="{ name: link.to }">
          <v-list-item-icon>
            <v-icon v-text="link.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="$t(link.title)" />
        </v-list-item>

        <v-list-group :key="i" sub-group no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="$t(link.title)" />
            </v-list-item-content>
          </template>

          <template v-for="(item, k) in link.items">
            <template v-if="isCan(item.permissions)">
              <v-list-item
                v-if="!item.subItems"
                :key="k"
                link
                :to="{ name: item.to }"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-text="$t(item.title)" />
              </v-list-item>

              <template v-else>
                <v-list-group :key="k" sub-group>
                  <template #prependIcon>
                    <v-icon
                      v-text="item.icon"
                      class="ml-4"
                      style="transform: none"
                    />
                  </template>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title v-text="$t(item.title)" />
                    </v-list-item-content>
                  </template>

                  <template v-for="(subItem, s) in item.subItems">
                    <v-list-item
                      v-if="isCan(subItem.permissions)"
                      :key="s"
                      link
                      :to="{ name: subItem.to }"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="subItem.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-title v-text="$t(subItem.title)" />
                    </v-list-item>
                  </template>
                </v-list-group>
              </template>
            </template>
          </template>
        </v-list-group>
      </template>
    </template>
  </v-list-group>
</template>

<script>
import { mapGetters } from 'vuex'

import { permissions } from '@/common/mixins'

import settingLinks from './settingLinks'

export default {
  name: 'SettingsList',
  mixins: [permissions],
  computed: {
    ...mapGetters(['currentUser']),
    settingLinks: () => settingLinks
  }
}
</script>
