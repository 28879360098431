/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import findIndex from 'lodash/findIndex'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import reject from 'lodash/reject'
import Vue from 'vue'

import { FETCH_LOCATIONS } from '../../actions.type'
import {
  CREATE_LOCATION,
  REMOVE_LOCATION,
  SET_LOCATION_ALL_REQUEST,
  SET_LOCATION_ALL_VALUE,
  UPDATE_LOCATION
} from '../../mutations.type'

const state = {
  all: {
    request: {
      isLoading: undefined,
      isSuccess: undefined,
      isError: undefined,
      isFatalError: undefined,
      delay: undefined,
      lastRequestTime: undefined
    },
    value: []
  }
}

const getters = {
  locationAllValue: (state) => state.all.value,
  locationAllRequest: (state) => state.all.request,
  locationAllById: (state) => keyBy(state.all.value, 'id'),
  locationAllByClientId: (state) => groupBy(state.all.value, 'client.id'),
  getLocationBussinesHoursById: (state) =>
    mapValues(keyBy(state.all.value, 'id'), (location) => {
      try {
        return JSON.parse(location.businessHours)
      } catch (error) {
        console.error('bussines hours parse error:', error.message)
        return null
      }
    })
}

const actions = {
  async [FETCH_LOCATIONS]({ commit, state }, params) {
    if (
      (state.all.request.isSuccess && !params?.isReload) ||
      state.all.request.isLoading ||
      state.all.request.isError
    ) {
      return
    }
    try {
      const { data } = await Vue.axios.get('location', {
        'axios-retry': {
          beforeTry: () => {
            commit(SET_LOCATION_ALL_REQUEST, {
              isLoading: true,
              isError: false
            })
          },
          afterTry: ({ delay, lastRequestTime }) => {
            commit(SET_LOCATION_ALL_REQUEST, {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime
            })
          }
        }
      })
      commit(SET_LOCATION_ALL_REQUEST, {
        isLoading: false,
        isSuccess: true
      })
      commit(SET_LOCATION_ALL_VALUE, data)
    } catch (e) {
      commit(SET_LOCATION_ALL_REQUEST, {
        isFatalError: true,
        isLoading: false
      })
    }
  },

  async SOCKET_CREATE_LOCATION({ commit }, value) {
    commit(CREATE_LOCATION, value)
  },

  async SOCKET_UPDATE_LOCATION({ commit }, value) {
    commit(UPDATE_LOCATION, value)
  },

  async SOCKET_REMOVE_LOCATION({ commit }, id) {
    commit(REMOVE_LOCATION, id)
  }
}

const mutations = {
  [SET_LOCATION_ALL_REQUEST](state, request) {
    state.all.request = {
      ...state.all.request,
      ...request
    }
  },
  [SET_LOCATION_ALL_VALUE](state, locations) {
    state.all.value = locations
  },
  [UPDATE_LOCATION](state, updatedLocation) {
    state.all.value.splice(
      findIndex(state.all.value, { id: updatedLocation.id }),
      1,
      updatedLocation
    )
  },
  [CREATE_LOCATION](state, createdLocation) {
    state.all.value.push(createdLocation)
  },
  [REMOVE_LOCATION](state, id) {
    state.all.value = reject(state.all.value, { id })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
