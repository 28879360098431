import each from 'lodash/each'
import { validationMessages } from 'vuelidate-messages'

import i18n from '@/i18n/i18n'

const vMessages = (vObject, options = {}) =>
  validationMessages(
    {
      required: () => i18n.t('validation.required'),

      isChangedValue: () => i18n.t('validation.isChangedValue'),

      numeric: () => i18n.t('validation.numeric'),

      phone: () => i18n.t('validation.phone'),

      url: () => i18n.t('validation.url'),

      email: () => i18n.t('validation.email'),

      emails: () => i18n.t('validation.emails'),

      length: ({ $params }) =>
        i18n.tc('validation.length', $params.length.value),
      /* @TODO: Check for length on options to get > 1 error */

      serverValidationError: ({ $params }) =>
        i18n.t(`validation.${$params.serverValidationError.errors}`),

      serverError: () => i18n.t('errorLoadingFromServer'),

      serverLoading: () => i18n.t('waitLoadingFromServer'),

      maxLength: ({ $params }) =>
        i18n.tc('validation.maxLength', $params.maxLength.max),

      minLength: ({ $params }) =>
        i18n.tc('validation.minLength', $params.minLength.min),

      sameAs: ({ $params }) => {
        const inputName = i18n.t(`${$params.sameAs.eq}`)
        return `${i18n.tc('validation.sameAs', inputName)}`
      },

      sameAsPassword: () => i18n.tc('validation.passwordMismatch'),

      alpha: () => i18n.t('validation.alpha'),

      alphaRuUaEn: () => i18n.t('validation.alpha'),

      password: ({ $params }) => {
        let mess = i18n.t('validation.password.mustContain')
        each($params.password.errors, (error, i) => {
          const e = i18n.t(`validation.password.${error}`)
          mess = `${mess}${i !== 0 ? ',' : ''} ${e}`
        })
        return mess
      },

      ipAddressVsPort: () => i18n.t('validation.ipVsPort'),

      minValue: ({ $params }) => i18n.tc('validation.minValue', $params.minValue.min),

      maxValue: ({ $params }) => i18n.tc('validation.maxValue', $params.maxValue.max),

      integer: () => i18n.tc('validation.integer')
    },
    options
  )(vObject)

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        validationMessages: vMessages
      }
    })
  }
}
