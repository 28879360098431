<template>
  <v-footer fixed inset :app="app" class="d-flex justify-space-between px-2">
    <div @click="changeTheme">
      <v-icon v-text="'mdi-theme-light-dark'" />
    </div>
    <div>
      {{ new Date().getFullYear() }} — <strong>CM Service Desk</strong> -
      <span
        v-if="version.front === globalSettingAllValue?.version"
        style="font-size: 0.8em; font-weight: 400"
      >
        {{ version.front }}
      </span>
      <span v-else style="font-size: 0.8em; font-weight: 400">
        {{ version.front }} / {{ globalSettingAllValue?.version }}
      </span>
    </div>
    <div>
      <locale-select />
    </div>
  </v-footer>
</template>

<script>
import { mapGetters } from 'vuex'

import LocaleSelect from '@/i18n/components/LocaleSelect.vue'
export default {
  components: { LocaleSelect },
  name: 'AppFooter',
  props: {
    app: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      darkTheme: false,
      version: {
        front: process.env.VUE_APP_VERSION
      }
    }
  },

  computed: {
    ...mapGetters(['globalSettingAllValue'])
  },

  methods: {
    changeTheme() {
      this.darkTheme = !localStorage.darkTheme
      if (this.darkTheme) {
        localStorage.darkTheme = true
        this.$vuetify.theme.dark = true
      } else {
        delete localStorage.darkTheme
        this.$vuetify.theme.dark = false
      }
    }
  },

  mounted() {
    this.$vuetify.theme.dark = localStorage.darkTheme
  }
}
</script>
