/* eslint no-shadow: ["error", { "allow": ["state"] }] */

import keyBy from 'lodash/keyBy'
import Vue from 'vue'

import i18n from '@/i18n/i18n'

import { FETCH_GLOBAL_SETTINGS } from '../../actions.type'
import {
  SET_GLOBAL_SETTING_ALL_REQUEST,
  SET_GLOBAL_SETTING_ALL_VALUE,
  UPDATE_GLOBAL_SETTING
} from '../../mutations.type'

const state = {
  all: {
    request: {
      isLoading: undefined,
      isSuccess: undefined,
      isError: undefined,
      isFatalError: undefined,
      delay: undefined,
      lastRequestTime: undefined
    },
    value: undefined
  }
}

const getters = {
  globalLocale: (state) =>
    state.all.value?.locales.map((v) => v.title).join(', '),
  isGlobalLocale: (state) =>
    state.all.value?.locales.some((v) => v.code === i18n.locale),
  globalSettingAllValue: (state) => state.all.value,
  globalSettingAllRequest: (state) => state.all.request,
  globalSettingAllById: (state) => keyBy(state.all.value, 'id')
}

const actions = {
  async [FETCH_GLOBAL_SETTINGS]({ commit, state }, params) {
    if (
      (state.all.request.isSuccess && !params?.isReload) ||
      state.all.request.isLoading ||
      state.all.request.isError
    ) {
      return
    }
    try {
      const { data } = await Vue.axios.get('global', {
        'axios-retry': {
          beforeTry: () => {
            commit(SET_GLOBAL_SETTING_ALL_REQUEST, {
              isLoading: true,
              isError: false
            })
          },
          afterTry: ({ delay, lastRequestTime }) => {
            commit(SET_GLOBAL_SETTING_ALL_REQUEST, {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime
            })
          }
        }
      })
      commit(SET_GLOBAL_SETTING_ALL_REQUEST, {
        isLoading: false,
        isSuccess: true
      })
      commit(SET_GLOBAL_SETTING_ALL_VALUE, data)
    } catch (e) {
      commit(SET_GLOBAL_SETTING_ALL_REQUEST, {
        isFatalError: true,
        isLoading: false
      })
    }
  },

  async SOCKET_UPDATE_GLOBAL({ commit }, value) {
    commit(UPDATE_GLOBAL_SETTING, value)
  }
}

const mutations = {
  [SET_GLOBAL_SETTING_ALL_REQUEST](state, request) {
    state.all.request = {
      ...state.all.request,
      ...request
    }
  },
  [SET_GLOBAL_SETTING_ALL_VALUE](state, globalSettings) {
    state.all.value = globalSettings
  },
  [UPDATE_GLOBAL_SETTING](state, updatedGlobalSetting) {
    state.all.value = {
      ...state.all.value,
      ...updatedGlobalSetting
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
