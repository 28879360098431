export default [
  {
    title: 'equipment',
    items: [
      {
        title: 'brands',
        icon: 'mdi-format-text-variant-outline',
        to: 'SettingsEquipmentBrand'
      },
      {
        title: 'types',
        icon: 'mdi-format-list-bulleted-type ',
        to: 'SettingsEquipmentType'
      },
      {
        title: 'models',
        icon: 'mdi-coffee-maker',
        to: 'SettingsEquipmentModel'
      }
    ],
    menu: false
  },
  {
    title: 'orders',
    items: [
      {
        title: 'statuses',
        icon: 'mdi-state-machine',
        to: 'SettingsOrderStatus'
      },
      {
        title: 'types',
        icon: 'mdi-format-line-spacing',
        to: 'SettingsOrderTypes'
      }
    ],
    menu: false
  },
  {
    title: 'administration',
    items: [
      { title: 'roles', icon: 'mdi-tune-variant', to: 'SettingsRoles' },
      {
        title: 'globals',
        icon: 'mdi-cog',
        to: 'SettingsGlobal'
      },
      {
        title: 'apiDocs',
        icon: 'mdi-api',
        to: 'SettingsSwagger',
        permissions: ['isApiDocAccess']
      }
    ],
    menu: false
  },
  {
    title: 'placement',
    items: [
      {
        title: 'countries',
        icon: 'mdi-flag',
        to: 'SettingsLocaleCountries'
      },
      {
        title: 'regions',
        icon: 'mdi-map-marker-distance',
        to: 'SettingsLocaleRegions'
      },
      {
        title: 'cities',
        icon: 'mdi-map-marker',
        to: 'SettingsLocaleCities'
      }
    ],
    menu: false
  },
  {
    title: 'faults',
    items: [
      {
        title: 'clientFaults',
        icon: 'mdi-account',
        subItems: [
          { title: 'all', to: 'SettingsClientFaults' },
          { title: 'groups', to: 'SettingsClientFaultGroups' }
        ]
      },
      {
        title: 'idents',
        icon: 'mdi-magnify-expand',
        subItems: [
          { title: 'all', to: 'SettingsIdentFaults' },
          { title: 'groups', to: 'SettingsIdentFaultGroups' }
        ]
      },
      {
        title: 'causeFaults',
        icon: 'mdi-help-rhombus',
        subItems: [
          { title: 'all', to: 'SettingsCauseFaults' },
          { title: 'groups', to: 'SettingsCauseFaultGroups' }
        ]
      }
    ],
    menu: false
  },
  {
    title: 'works',
    items: [
      { title: 'works', icon: 'mdi-hammer-wrench', to: 'SettingsWorks' },
      {
        title: 'workGroups',
        icon: 'mdi-format-list-group',
        to: 'SettingsWorkGroups'
      }
    ],
    menu: false
  },
  {
    title: 'parts',
    items: [
      { title: 'parts', icon: 'mdi-archive-cog', to: 'SettingsParts' },
      {
        title: 'partGroups',
        icon: 'mdi-format-list-group ',
        to: 'SettingsPartGroups'
      }
    ],
    menu: false
  }
]
