import Vue from 'vue'
import VueRouter from 'vue-router'

import i18n from '@/i18n/i18n'
import store from '@/store'
import { CHECK_AUTH } from '@/store/actions.type'
import {
  SET_DOCUMENT_TITLE,
  SET_LOADER_MODAL_VALUE
} from '@/store/mutations.type'

import nextFactory from './middleware'
import auth from './routes/auth'
import client from './routes/client'
import contract from './routes/contract'
import equipment from './routes/equipment'
import location from './routes/location'
import order from './routes/order'
import settings from './routes/settings'
import swagger from './routes/settings/swagger'
import staff from './routes/staff'
import zone from './routes/zone'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Orders' },
    meta: {
      breadCrumb: [{ text: 'home' }]
    }
  },
  ...auth,
  ...order,
  ...staff,
  ...client,
  ...settings,
  ...contract,
  ...location,
  ...equipment,
  ...zone,
  ...swagger,
  {
    path: '*',
    redirect: { name: 'Error' }
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/pages/ErrorPage.vue'),
    props: (route) => ({
      errorCode: route.params.errorCode || 404
    })
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  store.commit(SET_LOADER_MODAL_VALUE, {
    isLoading: true,
    isError: false,
    isFatalError: false
  })

  await store.dispatch(CHECK_AUTH)

  if (!to.meta.middleware) {
    return next()
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      store,
      to
    }
    const nextMiddleware = nextFactory(context, middleware, 1)

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

// router.beforeResolve(({ next }) => {
//   if (!store.getters.globalSettingAllRequest.isSuccess) {
//     store.dispatch(FETCH_GLOBAL_SETTINGS)
//   }

//   if (!store.getters.localeAllRequest.isSuccess) {
//     store.dispatch(FETCH_LOCALES)
//   }

//   return next()
// })

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    const title = to.meta.title
      ? i18n.t(`${to.meta.title}`)
      : i18n.t('defaultPageTitle')
    store.commit(
      SET_DOCUMENT_TITLE,
      title.charAt(0).toUpperCase() + title.slice(1)
    )
  })
})

export default router
