import en from 'vuetify/lib/locale/en'

export default {
  account: 'ანგარიში',
  accountNotActive: 'ანგარიში გასააკტიურებელია',
  accounts: 'ანგარიშები',
  active: 'აქტიური',
  add: 'დამატება',
  addAccount: 'ანგარიშის დამატება',
  addSelected: 'მონიშნულის დამატება',
  address: 'მისამართი',
  additionalLanguage: 'დამატებითი ენა',
  administration: 'ადმინისტრირება',
  all: 'ყველა',
  any: 'ნებისმიერი',
  apiDocs: 'API დოკუმენტაცია',
  apiKeys: 'API გასაღებები',
  apply: 'დადასტურება',
  allWeek: 'სრული კვირა',
  back: 'უკან',
  backToLoginPage: 'საწყის გვერდზე დაბრუნება',
  beverage: 'სასმელი',
  beverages: 'სასმელები',
  brand: 'ბრენდი',
  brands: 'ბრენდები',
  businessHours: 'სამუშაო საათები',
  byDays: 'დღეების მიხედვით',
  cancel: 'გაუქმება',
  cancelDelete: 'წაშლის გაუქმება',
  cantDeleteFirstDeleteRelations:
    'წაშლა დაუშვებელია, პირველ რიგში წაშალეთ დაკავშირებული  ობიექტები',
  cantDeleteDefaultValue: 'დეფოლტ მნიშვნელობის წაშლა დაუშვებელია',
  cause: 'მიზეზი',
  causeFaultGroup: 'ხარვეზების მიზეზი',
  causeFaultGroups: 'ხარვეზების მიზეზები',
  causeFaults: 'ხარვეზების მიზეზები',
  causeGroups: 'ხარვეზის მიზეზების ჯგუფი',
  causes: 'მიზეზები',
  changePassword: 'პაროლის ცვლილება',
  cities: 'ქალაქები',
  city: 'ქალაქი',
  client: 'მომხმარებელი',
  clientFault: 'გამოძახების მიზეზი',
  clientFaultGroup: ' გამოძახების მიზეზი',
  clientFaultGroups: 'გამოძახების კატეგორიები',
  clientFaults: 'გამოძახების მიზეზები',
  clients: 'მომხმარებლები',
  close: 'დახურვა',
  closed: 'დახურულია',
  code: 'კოდი',
  color: 'ფერი',
  comment: 'კომენტარი',
  completedDate: 'Დასრულების თარიღი',
  connectionLost: 'კავშირი დაიკარგა',
  connectionRestored: 'კავშირი აღდგენილია',
  confirmDelete: 'ნამდვილად გსურთ ჩანაწერის წაშლა?',
  confirmPassword: 'პაროლის დადასტურება',
  connectServerError: 'სერვერთან დაკავშირების პრობლემა',
  contactPerson: 'საკონტაქტო პირი',
  contactPersons: 'საკონტაქტო პირები',
  contract: 'ხელშეკრულება',
  contractor: 'შემსრულებელი',
  contracts: 'ხელშეკრულება',
  controlTechConditions: 'ტექნიკური პირობების კონრტოლი',
  counter: 'მრიცხველი',
  counterFrom: 'მრიცხველი დან',
  counterTo: 'მრიცხველი მდე',
  countries: 'ქვეყნები',
  country: 'ქვეყანა',
  create: 'შექმნა',
  createdBasedOrder: 'შექმნილია შეკვეთის საფუძველზე',
  createdDate: 'შექნის თარიღი',
  createdOrder: 'შექმნილია შეკვეთა',
  date: 'თარიღი',
  dateEnd: 'დასრულების თარიღი',
  dateStart: 'დაწყების თარიღი',
  days: 'დღეები',
  daysDot: 'დღეები.',
  decisionMakerName: 'გადაწყეტილების მიმღები პირი',
  decisionMakerPhone: 'გადაწყეტილების მიმღები პირის მობილურის ნომერი',
  default: 'დეფოლტ მნიშვნელობა',
  defaultLocationBusinessHours: 'სამუშაო საათები დეფოლტ მნიშვნელობა',
  defaultPageTitle: 'Mycoffee.ge DESK',
  defaultReactionTime: 'რეაგირების დრო დეფოლტ მნიშვნელობა',
  defaultSolutionTime: 'გადაწყვეტილების დრო დეფოლტ მნიშვნელობა',
  delete: 'წაშლა',
  deleteAccount: 'ანგარიშის წაშლა',
  deleteUserAccount: 'მომხმარებლის წაშლა',
  description: 'აღწერა',
  details: 'რეკვიზიტები',
  distance: 'მანძილი',
  distanceFromService: 'მანძილი სერვისცენტრიდან',
  document: 'დოკუმენტი',
  download: 'ჩამოტვირთვა',
  edit: 'რედაქტირება',
  editDate: 'თარიღის ცვლილება',
  employee: 'თანამშრომელი',
  eqTypes: 'მოწყობილობის ტიპი',
  equipment: 'მოწყობილობა',
  equipments: 'მოწყობილობები',
  error: 'შეცდომა',
  errorLoadingFromServer:
    'დაფიქსირდა შეცდომა სერვერიდან მონაცემების გამოთხოვისას',
  every: 'ყოველი',
  fault: 'ხარვეზი',
  faultCode: 'ბრალის კოდი',
  faultGroup: 'ხარვეზის ჯგუფი',
  faultGroups: 'ხარვეზების ჯგუფები',
  faults: 'ხარვეზები',
  filter: 'ფილტრი',
  filters: 'ფილტრები',
  firstDeleteAllRelations: 'პირველ რიგში წაშალეთ ყველა დაკავშირებული ელემენტი',
  firstName: 'სახელი',
  forgotPassword: 'დაგავიწყდათ პაროლი?',
  from: 'დაწყება',
  fromClients: 'კლიენტებისგან',
  generalInfo: 'ზოგადი ინფორმაცია',
  globals: 'გლობალურები',
  gps: 'gps',
  group: 'ჯგუფი',
  groups: 'ჯგუფები',
  hide: 'ჩახურვა',
  home: 'მთავარი',
  hours: 'დრო',
  hoursDot: 'დრო',
  identFaultGroups: 'გამოვლენილი ხარვეზების ჯგუფები',
  identFaults: 'გამოვლენილი ხარვეზები',
  idents: 'გამოვლინება',
  instruction: 'ინსტრუქცია',
  key: 'გასაღები',
  km: 'км',
  language: 'ენა',
  lastName: 'გვარი',
  loading: 'ჩატვირთვა',
  placement: 'მდებარეობა',
  places: 'მისამართები',
  location: 'ლოკაცია',
  locations: 'ლოკაციები',
  login: 'შესვლა',
  loginIncorect: 'არასწორი მომხმარებელი ან პაროლი',
  mailingList: 'საკომუნიკაციო ელფოსტის მიამართი',
  managerEmail: 'მენეჯერის ელ.ფოტა',
  managerName: 'მენეჯერის სახელი',
  managerPhone: 'მენეჯერის მობილური',
  maximum: 'მაქსიმუმ',
  maxReactionTimeByContract: 'მაქსიმალური რეაგირების დრო კონტრაქტის მიხედვით',
  maxSolutionTimeByContract:
    'ხარვეზის გადაჭრის მაქსიმალური დრო კონტრაქტის მიხედვით',
  maxTimeOfWorkByContract: 'მაქსიმალური სამუშაო დრო, კონტრაქტის მიხედვით',
  maxWorkTime: 'მაქსიმალური სამუშაო დრო სამუშაოს',
  middleName: 'მამის სახელი',
  minimum: 'მინიმუმ',
  minutes: 'წუთები',
  minutesDot: 'წუთები.',
  mode: 'რეჟიმი',
  model: 'მოდელი',
  models: 'მოდელები',
  new: 'ახალი',
  newPassword: 'ახალი პაროლი',
  nextTry: 'შემდეგი ცდა',
  name: 'სახელი',
  no: 'არა',
  noData: 'ჩანაწერი არ მოიოძებნა',
  notActive: 'არ არის აქტიური',
  notFound: 'არ მოიძებნა',
  notFoundDescription: 'რესურსი შესაძლოა გადატანილი ან წაშლილი იყოს',
  notReceiveCode: 'არ მიგიღიათ კოდი?',
  notRepeat: 'არაგამეორებადი',
  notification: 'შეტყობინება',
  notifications: 'შეტყობინებები',
  notificationTimeTaked: 'შეტყობინების მიღების დრო',
  notificationTimeReacted: 'რეაგირებამდე შეტყობინების დრო',
  notificationTimeResolved: 'შეტყობინების დრო გადაწყვეტამდე',
  now: 'ახლა',
  number: 'ნომერი',
  object: 'ობიექტი',
  objects: 'ობიექტები',
  onlyTheir: 'მხოლოდ ჩემი',
  opened: 'ღიაა',
  order: 'შეკვეთების სია',
  orderBusinessHours: 'შეკვეთების მიღების დრო',
  orderCreatorDetails: 'შეკვეთის დეტალები',
  orderCreatorName: 'შეკვეთის შემქნელის სახელი',
  orderCreatorPhone: 'შეკვეთის შემქნელი მობილური',
  orderCreatorType: 'შეკვეთის შემქნელის კატეგორია',
  orderEditRules: 'შეკვეთის კორექტირების წესები',
  orderStatus: 'შეკვეთის სტატუსი',
  orderStatuses: 'შეკვეთის სტატუსები',
  orderStatusHistory: 'შეკვეთების სტატუსების ისტორია',
  orderTriggerHint:
  'ახალი შეკვეთა შეიქმნება, როდესაც მიმდინარე გადავა ამ სტატუსში',
  orderType: 'შეკვეთის კატეგორია',
  orderTypes: 'შეკვეთის კატეგორიები',
  orders: 'შეკვეთები',
  otherFault: 'სხვა გამოძახების მიზეზი',
  otherM: 'სხვა',
  otherW: 'სხვა',
  owner: 'მფლობელი',
  owners: 'მფლობელები',
  part: 'ნაწილი',
  partGroup: 'ნაწილების ჯგუფები',
  partGroups: 'ნაწილების ჯგუფები',
  parts: 'ნაწილები',
  password: 'პაროლი',
  period: 'პერიოდი',
  permissions: 'ნებართვა',
  phone: 'ტელეფონი',
  phones: 'ტელეფონები',
  pressure: 'წნევა',
  range: 'დიაპაზონი',
  reaction: 'რეაქცია',
  reactionDate: 'სამუშაოს დაწყების თარიღი',
  reactionTime: 'რეაგირების დრო',
  reactionTimer: 'რეაგირების წამზომი',
  regions: 'რეგიონები',
  region: 'რეგიონი',
  removeSelected: 'მონიშნულის წაშლა',
  repeat: 'გამეორება',
  repeatedOrder: 'გამეორებადი შეკვეთა',
  resend: 'თავიდან გაგზავნა',
  reset: 'განულება',
  resetSort: 'დალაგების გადატვირთვა',
  retry: 'გამეორება',
  role: 'როლი',
  roles: 'როლები',
  save: 'შენახვა',
  savePassword: ' პაროლის შენახვა',
  salesByBeverages: 'გაყიდვები სასმელების მიხედვით',
  salesByWeekDays: 'გაყიდვები კვირის დღეების მიხედვით',
  salesByHours: 'გაყიდვების საათობრივად',
  search: 'ძებნა',
  selectAll: 'ყველას მონიშვნა',
  selectDaysOfTheWeek: 'აირჩიეთ კვირის დღეები',
  sendCode: 'კოდის გაგზავნა',
  serialNumber: 'სერიული ნომერ',
  serverError: 'სერვერის შეცდომა',
  serverValidationError: 'ვალიდაციის შეცდომა',
  serviceCenter: 'სერვის ცენტრი',
  settings: 'პარამეტრები',
  scanQrForServiceRequest: 'დაასკანერეთ QR  შეკეთების შეკვეთის დასაფიქსირებლად',
  shortName: 'სახელი',
  show: 'ჩვენება',
  showMore: 'მეტის ჩვენება',
  sla: 'ხელშეკრულების პირობები',
  solution: 'გადაწყვეტა',
  solutionDate: 'სამუშაოს დასრულების თარიღი',
  solutionTime: 'გადაწყვეტის დრო',
  solutionTimer: 'გჰადაწყვეტის წამზომი',
  sort: 'სორტირება',
  staff: 'თანამშრომელი',
  staffs: 'თანამშრომლები',
  state: 'მდგომარეობა',
  status: 'სტატუსი',
  statuses: 'სტატუსები',
  stopNotifications: 'შეტყობინებებუს შეჩერება',
  stopReactionTimer: 'რეაგირების ტაიმერის  შეჩერება',
  stopSolutionTimer: 'გადაწყვეტის ტაიმერის შეჩერება',
  superAdmin: 'სუპერ ადმინი',
  tableWidth: 'ცხრილის სიგანე',
  telegram: 'ტელეგრამი',
  telemetry: 'ტელემეტრია',
  time: 'დრო',
  timeByFaultType: 'დრო ხარვეზების ტიპების მიხედვით',
  timeDefault: 'დრო დეფლოტ მნიშვნელობა',
  timeOfWork: 'სამუშაო დრო',
  title: 'დასახელება',
  to: 'დამთავრებული',
  toRecoverPassword:
    'პაროლის აღსადგენად შეიყვანეთ თქვენი ტელეფონის ნომერი,სადაც გამოგეგზავნებათ აღდგენის SMS კოდი',
  total: 'ჯამი',
  transitionFrom: 'გადასვლა',
  transitionTo: 'გადასვლა',
  trigger: 'ტრიგერი',
  type: 'კატეგორია',
  types: 'კატეგორიები',
  unselectAll: 'მონიშვნის მოხსნა',
  user: 'მომხმარებელი',
  userName: 'მომხმარებლის სახელი',
  users: 'მოპმხმარებლები',
  verifyCode: 'კოდის ვერიფიკაცია',
  video: 'ვიდეო',
  voltage: 'ძაბვა',
  waitLoadingFromServer: 'გთხოვთ დაელოდოთ სერვერიდან ინფორმაციის ჩამოტვირთვას',
  weeks: 'კვირები',
  withoutAccount: 'ანგარიშის გარეშე',
  work: 'სამუშაო',
  workTime: 'მუშაობის დრო',
  workGroups: 'სამუშაო ჯგუფები',
  works: 'სამუშაოები',
  yes: 'დიახ',
  zone: 'ზონა',
  zones: 'ზონები',
  validation: {
    alpha: 'უნდა შეიცავდეს მხოლოდ ასოებს',
    alreadyTaked: 'დაკავებულია',
    alreadyStaff: 'შემსრულებელი უკვე მითითებულია, გადატვირთეთ გვერდი',
    email: 'ელექტრონული ფოსტის მისამართი',
    emails: 'ელექტრონული ფოსტის მისამართები',
    integer: 'უნდა იყოს მთელი რიცხვი',
    ipVsPort: 'ipVsPort',
    isChangedValue: 'აირჩიეთ ახალი მნიშვნელობა',
    length: 'უნდა შეიცავდეს {n} სიმბოლოს',
    maxLength: 'სიმბოლოების მაქსიმალური რაოდენობა {n}',
    maxValue: 'მაქსიმალური მნიშვნელობა - {n}',
    minLength: 'სიმბოლოების მინიმალური რაოდენობა {n}',
    minValue: 'მინიმალური მნიშვნელობა - {n}',
    numeric: 'უნდა შეიცავდეს ციფრებს',
    password: {
      hasLowerCase: 'შეიცავს პატარა სიმბოლოს',
      hasNumbers: 'შეიცავს ციფრს',
      hasSymbol: 'შეიცავს სიმბოლოს',
      hasUpperCase: 'შეიცავს დიდ სიმბოლოს',
      minLength: 'მინიმუმ 8 სიმბოლო',
      mustContain: 'პაროლი უნდა შეიცავდეს'
    },
    passwordMismatch: 'პაროლები არ ემთხვევა',
    phone: 'მობილური ნომერი',
    repeatedOrderDate:
    'ყურადღება! შემდეგი შეკვეთის თარიღი აღემატება მოწყობილობის ტექნიკური მომსახურების ხელშეკრულების მოქმედების ვადას, შეკვეთა შესაძლოა არ შეიქმნას!',
    required: 'სავალდებულოდ შესავსები ველი',
    sameAs: 'არ ემთხვევა',
    url: 'url'
  },
  weekDays: {
    sunday: 'კვირა',
    monday: 'ორშაბათი',
    tuesday: 'სამშაბათი',
    wednesday: 'ოთხშაბათი',
    thursday: 'ხუთშაბათი',
    friday: 'პარასკევი',
    saturday: 'შაბათი',
    holiday: 'დასვენების დღე',
    sun: 'კვირ',
    mon: 'ორ',
    tue: 'სამ',
    wed: 'ოთხ',
    thu: 'ხუთ',
    fri: 'პარა',
    sat: 'შაბ'
  },
  $vuetify: en
}
